<template>
  <div class="common-bg">
    <van-nav-bar title="收支明细" left-arrow @click-left="onClickLeft" />
    <van-list
      v-model:loading="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="_onLoad"
    >
      <div v-for="item in list" :key="item.id">
        <div class="stock-card">
          <div class="card-li">
            <span class="text-grey">金额：</span>
            <span class="font-weight">
              {{ item.money }}
              {{ moneyTypeFilte(item.moneyType) }}
            </span>
          </div>
          <div class="card-li">
            <span class="text-grey">时间：</span>
            <span class="font-weight">{{ item.createTime }}</span>
          </div>
          <div class="card-li">
            <span class="text-grey">描述：</span>
            <span class="font-weight">{{ item.note }}</span>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import pageMixin from '@/utils/mixins/page'
import { getIncome } from '@/utils/api.js'
export default {
  mixins: [pageMixin],
  name: 'income',
  computed: {
    moneyTypeFilte() {
      return function (val) {
        let str = ''
        if (val === '转让' || val === '购买货币') {
          str = '个'
        } else {
          str = '元'
        }
        return str
      }
    }
  },
  data() {
    return {
      list: []
    }
  },
  created() {},
  methods: {
    async _getList() {
      let param = {
        page: this.page,
        limit: this.limit,
        moneyType: ''
      }
      param.type = this.$route.query.type === 'today' ? 'today' : 'all'
      await getIncome(param).then((res) => {
        this.total = res.data.totalCount
        res.data.list.map((item) => {
          this.list.push(item)
        })
      })
    },
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.stock-card {
  padding: 15px;
  background: hsla(0, 0%, 100%, 0.7);
  border-radius: 8px;
  font-size: 14px;
  margin: 10px;
  .card-li {
    padding: 3px 0;
  }
}
</style>
